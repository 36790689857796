import React, {Component} from 'react';
import {
  Switch,
  BrowserRouter,
  navigate,
  Route,
} from "react-router-dom";
import Index from "./router/index"; 
import Productpage from "./router/productpage"; 
import Error from "./router/404"; 
class App extends Component {
  render() {
  
    return (
      <> 
        <BrowserRouter> 
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/detailproduk/:name" component={Productpage} />
          <Route component={Error} />
        </Switch>
    </BrowserRouter>
    </>
    );
  }
}

export default App;

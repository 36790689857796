import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
class Produk extends Component {
  render() {
    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "block", background: "rgba(0,0,0, .7)" }}
          onClick={onClick}
        />
      );
    }
    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "block", background: "rgba(0,0,0, .7)" }}
          onClick={onClick}
        />
      );
    }
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1170,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        // {
        //   breakpoint: 576,
        //   settings: {
        //     slidesToShow: 1,
        //   },
        // },
      ],
    };
    return (
      <div className="product-tab-slide">
        <div className="container">
          <div className="product-tab-slide__header">
            <h5>Koleksi Terbaru</h5>
            <div className="product-tab-slide__header__controller">
              <a className="btn -white" href="shop-fullwidth-4col.html">
                Lihat semua
              </a>
            </div>
          </div>
        </div>
        <div className="product-tab-slide__content">
          <Slider {...settings}>
            <div className="product-slide__item">
              <div className="product ">
                <div className="product-type"></div>
                <div className="product-thumb">
                  <a
                    className="product-thumb__image"
                    href="#"
                  >
                    <img src="/images/product/2.png" alt="Product image" />
                    <img src="/images/product/1.png" alt="Product image" />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-content__header"></div>
                  <a className="product-name" href="#">
                    Pelicin wajah
                  </a>
                  <div className="product-content__footer">
                    <h5 className="product-price--main">-</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-slide__item">
              <div className="product ">
                <div className="product-type"></div>
                <div className="product-thumb">
                  <a
                    className="product-thumb__image"
                    href="#"
                  >
                    <img src="/images/product/2.png" alt="Product image" />
                    <img src="/images/product/1.png" alt="Product image" />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-content__header"></div>
                  <a className="product-name" href="#">
                    Pelicin wajah
                  </a>
                  <div className="product-content__footer">
                    <h5 className="product-price--main">-</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-slide__item">
              <div className="product ">
                <div className="product-type"></div>
                <div className="product-thumb">
                  <a
                    className="product-thumb__image"
                    href="#"
                  >
                    <img src="/images/product/4.png" alt="Product image" />
                    <img src="/images/product/3.png" alt="Product image" />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-content__header"></div>
                  <a className="product-name" href="#">
                   Bulu mata magnet
                  </a>
                  <div className="product-content__footer">
                    <h5 className="product-price--main">-</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-slide__item">
              <div className="product ">
                <div className="product-type"></div>
                <div className="product-thumb">
                  <a
                    className="product-thumb__image"
                    href="#"
                  >
                    <img src="/images/product/5.png" alt="Product image" />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-content__header"></div>
                  <a className="product-name" href="#">
                    Sunisa BB cream
                  </a>
                  <div className="product-content__footer">
                    <h5 className="product-price--main">-</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-slide__item">
              <div className="product ">
                <div className="product-type"></div>
                <div className="product-thumb">
                  <a
                    className="product-thumb__image"
                    href="#"
                  >
                    <img src="/images/product/6.png" alt="Product image" />
                  </a>
                </div>
                <div className="product-content">
                  <div className="product-content__header"></div>
                  <a className="product-name" href="#">
                   Paket catokan dan hairdryer
                  </a>
                  <div className="product-content__footer">
                    <h5 className="product-price--main">-</h5>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
export default Produk;

import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
class Head extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,

      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="slider -style-3 slider-arrow-middle">
        <div className="slider__carousel">
          <Slider {...settings}>
            <div className="slider__carousel__item slider-3">
              <div className="container">
                <div className="slider-background">
                  <img
                    className="slider-background"
                    src="/images/slider/SliderThree/2.png"
                    alt="Slider background"
                  />
                </div>
                <div className="slider-content">
                  <h1
                    className="slider-content__title"
                    data-animation-in="fadeInUp"
                    data-animation-delay="0.2"
                  >
                    Sunisa BB Cream
                  </h1>
                  <p
                    className="slider-content__description"
                    data-animation-in="fadeInUp"
                    data-animation-delay="0.3"
                  >
                    Tahan air, walaupun pakai mandi berenang still masih lekat
                    elok, berenang sampai 3 jam
                  </p>
                  <div
                    data-animation-in="fadeInUp"
                    data-animation-out="fadeInDown"
                    data-animation-delay="0.4"
                  >
                    <a
                      className="btn -red"
                      href="https://web.facebook.com/commerce/products/sunisa-foundation-originalsunisa-air-cushionoriginalsunisa-bb-cream/3664113283691846?ref=mini_shop_items_for_you&referral_code=mini_shop_profile_plus_shop_tab_cta"
                    >
                      Lihat koleksi
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="slider__carousel__item slider-3">
              <div className="container">
                <div className="slider-background">
                  <img
                    className="slider-background"
                    src="/images/slider/SliderThree/1.png"
                    alt="Slider background"
                  />
                </div>
                <div className="slider-content">
                  <h1
                    className="slider-content__title"
                    data-animation-in="fadeInUp"
                    data-animation-delay="0.2"
                  >
                    Bulu mata magnet
                  </h1>
                  <p
                    className="slider-content__description"
                    data-animation-in="fadeInUp"
                    data-animation-delay="0.3"
                  >
                    Salah satu jenis bulu mata palsu yang sedang tren belakangan
                    ini
                  </p>
                  <div
                    data-animation-in="fadeInUp"
                    data-animation-out="fadeInDown"
                    data-animation-delay="0.4"
                  >
                    <a className="btn -red" href="#">
                      Lihat koleksi
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
export default Head;

import React, { Component } from "react";
import Head from "../part/header";
import Footer from "../part/footer";
class Error extends Component {
  render() {
    return (
      <>
        <Head />
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="errorwrap text-center">
                  <img src="/images/eror.png" />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default Error;

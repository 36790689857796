import React, { Component } from "react";
class Gap extends Component {
  render() {
    return (
      <div
        className="introduction-five"
        style={{backgroundImage: "url('/images/introduction/IntroductionThree/bgg.png')"}}>
        <div className="container">
          <div className="introduction-five__content">
            <h2>Dapatkan Penawaran Menarik</h2>
            <a className="btn -red" href="shop-fullwidth-4col.html">
              Kunjungi toko sekarang{" "}
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}
export default Gap;

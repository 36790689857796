import React, {Component} from 'react';
import Head from "../part/header";
import Hero from "../part/hero";
import About from "../part/about";
import Product from "../part/product";
import Gap from "../part/gap";
import Footer from "../part/footer";
class Index extends Component {
  render() {
  
    return (
      <>
      <Head />
      <div className="content">
       
        <Hero />
        <About />
        <Product />
        <Gap />
        <Footer />

    </div>
    </>
    );
  }
}

export default Index;

import React, { Component } from "react";
import Head from "../part/header";
import Footer from "../part/footer"; 
class Productpage extends Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      ini: false,
      DataisLoaded: false,
    };
  }

  // ComponentDidMount is used to
  // execute the code
  componentDidMount() {
    fetch("https://clopzstoreserver.vercel.app/" + this.props.match.params.name)
      .then((res) => res.json())
      .then((json) => {
        if (json.length) {
          this.setState({
            items: json,
            ini: true,
            DataisLoaded: true,
          });
        } 
      });
  };
  render() {
    const { DataisLoaded, items, ini } = this.state;
    return (
      <>
        <div>
          <Head />
          {ini === true ? (
						<h1></h1>
					) : (
							<>
					       <div className="img-eror icon icon-plant"></div>
						<h1
							style={{
								textAlign: "center",
								paddingTop: "100px",
								paddingBottom: "100px",
							}}
						>
							Maaf produk tidak ditemukan
						</h1>
						</>
					)}
          {items.map((item) => (
            <ol key={item.id}>User_Name: {item.namakategori}</ol>
          ))}
          <Footer />
        </div>
      </>
    );
  }
}

export default Productpage;
